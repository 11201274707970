import { FC } from "react";

interface Props {
    size: number;
    color?: string;
    [key: string]: any;
}

const Lose: FC<Props> = ({ size, color, ...delegated }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...delegated}
        >
            <g clipPath="url(#clip0_160:5396)">
                <path
                    d="M3.93762 3.93738C-0.792537 8.66754 -0.792537 16.3325 3.93762 21.0626C8.66778 25.7928 16.3327 25.7928 21.0629 21.0626C25.793 16.3325 25.793 8.66754 21.0629 3.93738C16.3327 -0.792779 8.66778 -0.792779 3.93762 3.93738ZM18.4388 8.4949C18.6667 8.72278 18.6667 9.09567 18.4388 9.32354L15.2624 12.5L18.4388 15.6765C18.6667 15.9043 18.6667 16.2772 18.4388 16.5051L16.5053 18.4386C16.2775 18.6665 15.9046 18.6665 15.6767 18.4386L12.5002 15.2621L9.32379 18.4386C9.09591 18.6665 8.72302 18.6665 8.49515 18.4386L6.56165 16.5051C6.33377 16.2772 6.33377 15.9043 6.56165 15.6765L9.73811 12.5L6.56165 9.32354C6.33377 9.09567 6.33377 8.72278 6.56165 8.4949L8.49515 6.56141C8.72302 6.33353 9.09591 6.33353 9.32379 6.56141L12.5002 9.73786L15.6767 6.56141C15.9046 6.33353 16.2775 6.33353 16.5053 6.56141L18.4388 8.4949Z"
                    fill={color || "#DCD6FF"}
                />
            </g>
            <defs>
                <clipPath id="clip0_160:5396">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Lose;
