import { FC } from "react";

interface Props {
    size?: number | string; 
    color?: string; 
}

const VolumeUp: FC<Props> = ({ size, color }) => {
    return (
        <svg
            width={size || "25"}
            height={size || "25"}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_262:4480)">
                <path
                    d="M9.3329 4.47253L5.47135 8.33321H1.04167C0.466146 8.33321 0 8.79935 0 9.37488V15.6249C0 16.2 0.466146 16.6665 1.04167 16.6665H5.47135L9.3329 20.5272C9.98524 21.1796 11.1111 20.7212 11.1111 19.7907V5.20908C11.1111 4.27765 9.98437 3.82106 9.3329 4.47253ZM19.4596 2.25552C18.9748 1.93738 18.3233 2.07149 18.0052 2.55717C17.6866 3.04198 17.822 3.69345 18.3069 4.01159C21.1832 5.89918 22.8997 9.07236 22.8997 12.5003C22.8997 15.9283 21.1832 19.1014 18.3069 20.989C17.822 21.3067 17.6866 21.9586 18.0052 22.443C18.3108 22.9079 18.957 23.075 19.4596 22.7447C22.9284 20.4678 25 16.6375 25 12.4999C25 8.36229 22.9284 4.53243 19.4596 2.25552ZM20.8333 12.4999C20.8333 9.7425 19.4418 7.20734 17.1107 5.71863C16.625 5.40873 15.9809 5.55283 15.6732 6.04241C15.3655 6.53199 15.5091 7.18 15.9948 7.49033C17.7201 8.59232 18.75 10.4647 18.75 12.4999C18.75 14.535 17.7201 16.4074 15.9948 17.5094C15.5091 17.8193 15.3655 18.4673 15.6732 18.9573C15.9557 19.407 16.5898 19.6145 17.1107 19.2811C19.4418 17.7924 20.8333 15.2577 20.8333 12.4999ZM14.6801 9.1635C14.1775 8.88876 13.5434 9.06975 13.2648 9.57366C12.9874 10.0776 13.171 10.7108 13.6749 10.989C14.2352 11.2968 14.5833 11.8762 14.5833 12.4999C14.5833 13.124 14.2352 13.703 13.6753 14.0107C13.1714 14.2889 12.9878 14.9222 13.2652 15.4261C13.5443 15.9322 14.1788 16.1119 14.6806 15.8362C15.9058 15.1613 16.6671 13.8831 16.6671 12.4994C16.6671 11.1158 15.9058 9.83798 14.6801 9.1635Z"
                    fill={color || "black"}
                />
            </g>
            <defs>
                <clipPath id="clip0_262:4480">
                    <rect width="25" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default VolumeUp;
