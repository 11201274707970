import { FC } from "react";

interface Props {
    size?: number | string;
    color?: string;
}

const VolumeMute: FC<Props> = ({ size, color }) => {
    return (
        <svg
            width={size || "26"}
            height={size || "26"}
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_262:4478)">
                <path
                    d="M11.3965 3.99052L7.05225 8.33378H2.06885C1.42139 8.33378 0.896973 8.8582 0.896973 9.50566V16.5369C0.896973 17.1839 1.42139 17.7088 2.06885 17.7088H7.05225L11.3965 22.052C12.1304 22.7859 13.397 22.2703 13.397 21.2234V4.81913C13.397 3.77128 12.1294 3.25761 11.3965 3.99052ZM23.438 13.0213L25.6665 10.7928C25.9741 10.4851 25.9741 9.98612 25.6665 9.67851L24.5522 8.56425C24.2446 8.25663 23.7456 8.25663 23.438 8.56425L21.2095 10.7928L18.981 8.56425C18.6733 8.25663 18.1743 8.25663 17.8667 8.56425L16.7524 9.67851C16.4448 9.98612 16.4448 10.4851 16.7524 10.7928L18.981 13.0213L16.7529 15.2493C16.4453 15.5569 16.4453 16.056 16.7529 16.3636L17.8672 17.4778C18.1748 17.7854 18.6738 17.7854 18.9814 17.4778L21.2095 15.2498L23.438 17.4783C23.7456 17.7859 24.2446 17.7859 24.5522 17.4783L25.6665 16.3641C25.9741 16.0564 25.9741 15.5574 25.6665 15.2498L23.438 13.0213Z"
                    fill={color || "black"}
                />
            </g>
            <defs>
                <clipPath id="clip0_262:4478">
                    <rect
                        width="25"
                        height="25"
                        fill="white"
                        transform="translate(0.896973 0.521484)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default VolumeMute;
